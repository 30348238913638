<ion-content class="ion-padding" slot="center">
  <div class="content-wrapper">
    <div class="login-container">
      <div class="logo-div">
        <img src="../../../../assets/images/rg_logo.svg" alt="logo" class="logo">
      </div>
      <h2>Welcome Back!</h2>
      
      <!-- Tab Navigation -->
      <ion-segment [(ngModel)]="loginMethod" class="login-segment">
        <ion-segment-button value="otp">
          OTP Login
        </ion-segment-button>
        <ion-segment-button value="email">
          Email Login
        </ion-segment-button>
      </ion-segment>

      <!-- Email/Password Login Form -->
      <form *ngIf="loginMethod === 'email'" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <ion-item class="input-item">
          <ion-label position="floating">Email</ion-label>
          <ion-input type="email" formControlName="email" required></ion-input>
        </ion-item>
        <div *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.touched || loginForm.get('email')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="loginForm.get('email')?.errors?.['required']">Email is required.</small>
          <small class="error-message" *ngIf="loginForm.get('email')?.errors?.['email']">Invalid email format.</small>
        </div>

        <ion-item class="input-item">
          <ion-label position="floating">Password</ion-label>
          <ion-input type="password" formControlName="password" required></ion-input>
        </ion-item>
        <div *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.touched || loginForm.get('password')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="loginForm.get('password')?.errors?.['required']">Password is required.</small>
          <small class="error-message" *ngIf="loginForm.get('password')?.errors?.['minlength']">Password must be at least 6 characters long.</small>
        </div>

        <ion-button expand="full" type="submit" class="login-button" [disabled]="loginForm.invalid">Login</ion-button>
      </form>

      <!-- OTP Login Form -->
      <form *ngIf="loginMethod === 'otp'" [formGroup]="otpForm" (ngSubmit)="onOtpSubmit()">
        <ion-item class="input-item">
          <ion-label position="floating">Phone Number</ion-label>
          <ion-input type="tel" formControlName="mobile_number" appPhoneNumber required (ionInput)="onPhoneInput($event)"></ion-input>
        </ion-item>
        <div *ngIf="otpForm.get('mobile_number')?.invalid && (otpForm.get('mobile_number')?.touched || otpForm.get('mobile_number')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="otpForm.get('mobile_number')?.errors?.['required']">Phone number is required.</small>
          <small class="error-message" *ngIf="otpForm.get('mobile_number')?.errors?.['pattern']">Invalid phone number format.</small>
        </div>

        <ion-item class="input-item" *ngIf="otpSent">
          <ion-label position="floating">Enter OTP</ion-label>
          <ion-input type="text" formControlName="otp" required></ion-input>
        </ion-item>

        <div class="resend-otp-container" *ngIf="otpSent">
          <ion-button *ngIf="canResendOtp" class="resend-otp-button" [disabled]="!canResendOtp" size="small" color="primary" fill="outline" (click)="sendVerifyOtp('send')">
            Resend OTP
          </ion-button>
          <p *ngIf="countdown > 0" class="resend-otp-text" >Resend OTP in {{ countdown }} seconds.</p>
        </div>
      
        <ion-button expand="full" type="submit" class="otp-button" [disabled]="loginMethod === 'otp' ? otpForm.invalid : loginForm.invalid">
          {{ otpSent ? 'Verify OTP' : 'Send OTP' }}
        </ion-button>
      </form>

      <div class="signup-link">
        <p>New User? <span (click)="movetoSignup()">Signup</span></p>
      </div>
    </div>
  </div>
</ion-content>