import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Borrower, ProductParams, SESSION_STORAGE_KEYS, UPLOAD_DOCUMENT_DATA } from '../types/types';
import { UploadDocumentComponent } from '../components/upload-document/upload-document.component';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  userSessionCreated = new Subject();

  constructor(private modalController: ModalController) { }

  setDataToSessionStorage({ key, data }: { key: SESSION_STORAGE_KEYS; data: any }) {
    const encryptedData = this.encryptData(data);
    sessionStorage.setItem(key, encryptedData);
  }

  removeDataToSessionStorage(key: SESSION_STORAGE_KEYS) {
    sessionStorage.removeItem(key);
  }

  getDataFromSessionStorage(key: SESSION_STORAGE_KEYS) {
    const encryptedData = sessionStorage.getItem(key);
    if (encryptedData) {
      return this.decryptData(encryptedData);
    }
    return null;
  }

  encryptData(data: any) {
    const stringData = JSON.stringify(data);
    return CryptoJS.AES.encrypt(stringData, environment.SECRET_KEY).toString();
  }

  decryptData(ciphertext: string): any {
    try {
      const bytes = CryptoJS.AES.decrypt(ciphertext, environment.SECRET_KEY);
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      return new Function('return ' + decryptedText)();
    } catch (error) {
      return null;
    }
  }

  getAgeFromDob(date_of_birth: string) {
    if (!date_of_birth) {
      return '';
    }
    const dob = new Date(date_of_birth);
    const today = new Date();

    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  clearGrantSession() {
    this.removeDataToSessionStorage(SESSION_STORAGE_KEYS.ACTIVE_CONTEXT)
  }

  mapProductParams(borrower: Borrower) {
    try {
      const age = this.getAgeFromDob(borrower?.dob);
      const location = borrower?.state || borrower?.district || borrower?.city || ''
      const product_params = {
        borrowerId: borrower.id,
        age: age || '',
        gender: borrower?.gender?.toLowerCase() || '',
        location: location?.toLowerCase()
      }
      const encrypted_params = this.encryptData(product_params)
      return encrypted_params || null
    } catch (error) {
      return null
    }

  }
  getProductParamsFromUrl(encrypted_params: string) {
    try {
      const decrypted_params = this.decryptData(encrypted_params)
      return decrypted_params || null
    } catch (error) {
      return null;
    }
  }
  clearSessionStorage() {
    sessionStorage.clear();
  }

  setTransactionIdWithProductParams(transaction_id:string,encrypted_roduct_params:string){
    localStorage.setItem(transaction_id,encrypted_roduct_params)
  }
}
