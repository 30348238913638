import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UPLOAD_DOCUMENT_DATA } from '../../types/types';
import { ModalController } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { ToastService } from '../../services/toaster.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent {
  @Input() uploadDocumentData!: UPLOAD_DOCUMENT_DATA
  @Output() documentUploaded = new EventEmitter<File | null>();
  form = this.fb.group({
    panCardNumber: ['', [Validators.required, Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
    aadharCardNumber: ['', [Validators.required, Validators.pattern('[0-9]{12}')]],
    file: [null as File | null, Validators.required]
  });
  constructor(private modalController: ModalController, private apiService: ApiService,private toasterService: ToastService, private fb: FormBuilder) { }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.form.patchValue({file: input.files[0]});
    }
  }

  async onSubmit(): Promise<void> {
    const info = {
      file : this.uploadDocumentData.dataRequired.pan_file ? this.form.value.file : null,
      pan_card_number: this.uploadDocumentData.dataRequired.pan_file ? this.form.value.panCardNumber : null,
      aadhar_card_number: this.uploadDocumentData.dataRequired.aadharCardNumber ? this.form.value.aadharCardNumber : null,
      pan_card_image: '',
    }

    if(this.uploadDocumentData.dataRequired.panCardNumber && !info.pan_card_number){
      this.toasterService.presentToast('Please fill in PAN card number', 'warning');
      return;
    }

    if(this.uploadDocumentData.dataRequired.aadharCardNumber && !info.aadhar_card_number){
      this.toasterService.presentToast('Please fill in Aadhar card number', 'warning');
      return;
    }

    if (this.uploadDocumentData.dataRequired.pan_file) {
      const s3Link = await this.convertBinaryToS3(info.file as File);
      if (!s3Link) {
        this.toasterService.presentToast('Please select a file', 'warning');
        return;
      }
      info.pan_card_image = s3Link;
    }
  

    this.modalController.dismiss(info);
  }

  async convertBinaryToS3(file: File): Promise<string | void> {
    if (!file) {
      this.toasterService.presentToast('Please select a file', 'warning');
      return;
    }
    const payload = {
      pan_card_image: file
    };
    try {
      const res: any = await this.apiService.convertBinaryToS3FileLink(payload);
      if (!res?.data?.s3_url) {
        this.toasterService.presentToast('Failed to upload document', 'danger');
        return;
      }
      return res.data.s3_url;
    } catch (error) {
      this.toasterService.presentToast('Failed to upload document', 'danger');
    }
  }

  onCancel(): void {
    this.modalController.dismiss();
  }
  removeFile() {
    this.form.patchValue({file: null});
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  validatePanCard() {
    console.log(this.form.value.panCardNumber);
  }

  validateAadharCard() {
    console.log(this.form.value.aadharCardNumber);
  }

}