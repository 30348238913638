import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginPayload, PAN_CARD_UPLOAD, ProviderConfirmPayload, ProviderInitPayload, ProviderSearchPayload, RefreshTokenPayload, S3_DOCUMENT_DATA, SendVerifyOtpPayload, SignUpPayload } from '../types/types';
import { ENDPOINTS } from '../endpoints/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {}

  verifyToken() {
    return this.http.get(ENDPOINTS.VERIFY_TOKEN);
  }

  refreshToken(payload: RefreshTokenPayload) {
    return this.http.post(ENDPOINTS.REFRESH_TOKEN, payload);
  }

  getBorrowersListByAgentId(page: number, page_size: number, search: string, borrowerId: string) {
    const params = { page, page_size: page_size, search, id: borrowerId };
    return this.http.get(ENDPOINTS.BORROWERS, { params });
  }

  signUpUser(payload: SignUpPayload) {
    return this.http.post(ENDPOINTS.SIGN_UP, payload);
  }

  loginUser(payload: LoginPayload) {
    return this.http.post(ENDPOINTS.LOGIN, payload);
  }

  sendVerifyOtp(payload: SendVerifyOtpPayload , type: 'send' | 'verify') {
    return this.http[type === 'send' ? 'post' : 'put'](ENDPOINTS.SEND_VERIFY_OTP, payload);
  }

  providerSearch(payload: ProviderSearchPayload) {
    return this.http.post(ENDPOINTS.PROVIDER_SEARCH, payload);
  }
  providerInit(payload: ProviderInitPayload) {
    return this.http.post(ENDPOINTS.PROVIDER_INIT, payload);
  }
  providerConfirm(payload: ProviderConfirmPayload) {
    return this.http.post(ENDPOINTS.PROVIDER_CONFIRM, payload);
  }
  convertBinaryToS3FileLink(payload: S3_DOCUMENT_DATA) {
    const formData = new FormData();
    formData.append('pan_card_image', payload.pan_card_image);
    return this.http.post(ENDPOINTS.UPLOAD_DOCUMENT, formData).toPromise();
  }
  uploadPanCard(payload: PAN_CARD_UPLOAD) {
    return this.http.post(ENDPOINTS.UPLOAD_PAN_URL, payload);
  }
  getLoggedInUser() {
    return this.http.get(ENDPOINTS.ME);
  }
}

