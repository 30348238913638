<div class="section">
    <div class="cont_principal cont_error_active">
      <div class="cont_error">
        <h1>Oops</h1>
        <p>Your session has expired or is not active.</p>
        <ion-button (click)="navigateUser()" color="primary">
          <ion-icon [name]="sessionFound ? 'home' : 'log-in-outline'"></ion-icon> {{sessionFound ? 'Home' : 'Login'}}
        </ion-button>
      </div>
      <div class="cont_aura_1" (click)="navigateUser()"></div>
      <div class="cont_aura_2" (click)="navigateUser()"></div>
    </div>
  </div>
  