<ion-content class="ion-padding" slot="center">
  <div class="content-wrapper">
    <div class="signup-container">
      <div class="logo-div">
        <img src="../../../../assets/images/rg_logo.svg" alt="logo" class="logo">
      </div>
      <h2>Create Account</h2>
      <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <ion-item class="input-item">
          <ion-label position="floating">Name</ion-label>
          <ion-input type="text" formControlName="full_name" required></ion-input>
        </ion-item>
        <div *ngIf="signupForm.get('full_name')?.invalid && (signupForm.get('full_name')?.touched || signupForm.get('full_name')?.dirty)" class="error-head">
          <small class="error-message">Name is required.</small>
        </div>

        <ion-item class="input-item">
          <ion-label position="floating">Mobile Number</ion-label>
          <ion-input appPhoneNumber type="tel" formControlName="mobile_number" required></ion-input>
        </ion-item>
        <div *ngIf="signupForm.get('mobile_number')?.invalid && (signupForm.get('mobile_number')?.touched || signupForm.get('mobile_number')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="signupForm.get('mobile_number')?.errors?.['required']">Mobile number is required.</small>
          <small class="error-message" *ngIf="signupForm.get('mobile_number')?.errors?.['pattern']">Mobile number must be 10 digits.</small>
        </div>

        <ion-item class="input-item">
          <ion-label position="floating">Email</ion-label>
          <ion-input type="email" formControlName="email" required></ion-input>
        </ion-item>
        <div *ngIf="signupForm.get('email')?.invalid && (signupForm.get('email')?.touched || signupForm.get('email')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="signupForm.get('email')?.errors?.['required']">Email is required.</small>
          <small class="error-message" *ngIf="signupForm.get('email')?.errors?.['email']">Invalid email format.</small>
        </div>

        <ion-item class="input-item">
          <ion-label position="floating">Password</ion-label>
          <ion-input type="password" formControlName="password" required></ion-input>
        </ion-item>
        <div *ngIf="signupForm.get('password')?.invalid && (signupForm.get('password')?.touched || signupForm.get('password')?.dirty)" class="error-head">
          <small class="error-message" *ngIf="signupForm.get('password')?.errors?.['required']">Password is required.</small>
          <small class="error-message" *ngIf="signupForm.get('password')?.errors?.['minlength']">Password must be at least 6 characters long.</small>
        </div>

        <ion-button expand="full" type="submit" class="signup-button" [disabled]="signupForm.invalid">Sign Up</ion-button>
      </form>
      <div class="login-link">
        <p>Already have an account? <span (click)="movetoLogin()">Login</span></p>
      </div>
    </div>
  </div>
</ion-content>
