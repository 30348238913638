export const environment = {
  production: false,
  API : {
    RG_SEEKER_BACKEND_BASE_URL: {
      BASE_URL: 'https://samhita-rg-seeker-be-dev.samhita.org/api',
      END_POINT: {
        AUTH: {
          VERIFY_TOKEN: '/auth/verify-token/',
          BORROWERS: '/auth/borrowers',
          SIGN_UP: '/auth/signup/',
          LOGIN: '/auth/login/',
          REFRESH_TOKEN: '/auth/token/refresh/',
          SEND_VERIFY_OTP: '/auth/agent-otp-login/',
          UPLOAD_DOCUMENT: '/auth/upload-pan-image/',
          UPLOAD_PAN_URL: '/auth/update-pan-image-url/',
          ME: '/auth/me/'
        },
        SEEKER: {
          SEARCH : '/seeker/search/',
          INIT: '/seeker/init/',
          CONFIRM: '/seeker/confirm/'
        }
      }
    },
  },
  SECRET_KEY : 'aB3dE5fG7hJ9kL2mN4pQ6rS8tU0vW3xY'
};
