import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';
import { ApiService } from 'src/app/shared/services/api.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ToastService } from 'src/app/shared/services/toaster.service';
import { LoginPayload, SendVerifyOtpPayload } from 'src/app/shared/types/types';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginMethod: string = 'otp';
  countdown = 30;
  otpSent: boolean = false;
  private countdownSubscription!: Subscription;
  canResendOtp: boolean = true;

  loginForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });
  otpForm = this.formBuilder.group({
    mobile_number: ['', [Validators.required,Validators.minLength(10)]],
    otp: ['', [Validators.minLength(6), Validators.maxLength(6)]]
  });

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.clearSession();
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.toastService.presentToast('Please fill in all fields correctly.', 'warning');
      return;
    }

    const payload = this.loginForm.value as LoginPayload

    this.apiService.loginUser(payload).subscribe(
      (response: any) => {
        const tokens = { access: response.access, refresh: response.refresh };
        if (!tokens.access || !tokens.refresh) {
          this.toastService.presentToast('Failed to get the data!', 'danger');
          return;
        }
        this.toastService.presentToast(response.message || 'User logged in successfully!', 'success', 2000, 'bottom');
        this.getInSideApp(tokens);
      }
    );
  }

  startCountdown() {
    this.countdown = 30;
    this.canResendOtp = false;

    this.countdownSubscription = interval(1000).subscribe(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        this.canResendOtp = true;
        this.countdownSubscription.unsubscribe();
      }
    });
  }


  onOtpSubmit() {
    if (!this.otpSent) {
      this.sendVerifyOtp('send');
    } else {
      this.sendVerifyOtp('verify');
    }
  }
  sendVerifyOtp(type: 'send' | 'verify') {
    const payload = this.createOtpPayload(type);
    if (!payload) return;

    this.apiService.sendVerifyOtp(payload, type).subscribe(
      (response: any) => {
        console.log(response);
        this.handleOtpResponse(response, type);
      }
    );
  }

  private createOtpPayload(type: 'send' | 'verify'): SendVerifyOtpPayload | null {
    const payload = { ...this.otpForm.value } as SendVerifyOtpPayload;
    if (type === 'send') {
      delete payload.otp;
    } else if (type === 'verify' && payload.otp?.length !== 6) {
      this.toastService.presentToast('Please enter a valid OTP!', 'danger');
      return null;
    }
    return payload;
  }

  private handleOtpResponse(response: any, type: 'send' | 'verify') {
    if (type === 'send') {
      this.otpSent = true;
      this.startCountdown();
    } else if (type === 'verify') {
      const { access, refresh } = response.data;
      if (!access || !refresh) {
        this.toastService.presentToast('Failed to get the data!', 'danger');
        return;
      }
      this.toastService.presentToast(response.message || 'User logged in successfully!', 'success', 2000, 'bottom');
      this.getInSideApp({ access, refresh });
    }
  }

  getInSideApp(tokens: { access: string, refresh: string }){
    const fromApp = true;
    this.router.navigate([`auth/${tokens.access}`], {
      queryParams: { refresh: tokens.refresh || '', fromApp }
    });
  }

  clearSession() {
    this.loginForm.reset();
    this.sharedService.clearSessionStorage()
  }

  movetoSignup() {
    this.router.navigate(['/signup']);
  }
  onPhoneInput(event: any) {
    this.otpForm.get('otp')?.reset()
    if (event.target.value.length !== 10) {
      this.countdown = 30;
      this.canResendOtp = false;
      this.countdownSubscription?.unsubscribe();
      this.otpSent = false
    }
  }

}
