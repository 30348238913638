<ion-app>
  <app-header *ngIf="showHeader"></app-header>

  <ion-split-pane contentId="main-content" style="margin-top: 4.4rem;">
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>

  <ion-footer>
    <app-footer></app-footer>
  </ion-footer>
  <!-- <div style="position: absolute;bottom: 1px;display: flex;align-items: center;justify-content: space-between;width: 100%;">
    <ion-button expand="block" style="width: 100%;margin: 0 4px;" color="light"   *ngIf="showOpenAppButton" (click)="openInApp()">
      Open in App
    </ion-button>
  </div> -->
  <app-overlay-loading></app-overlay-loading>
</ion-app>
