import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { SESSION_STORAGE_KEYS } from '../../types/types';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router , private sharedService: SharedService) {}

  sessionFound = false;
  ngOnInit(): void {
    this.sessionFound =  this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.USER_INFO);
  }
  navigateUser() { 
    this.router.navigate([this.sessionFound ?  '' : '/login']); 
  }
}
