import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneNumber]'
})
export class PhoneNumberDirective {

  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: any): void {
    const input = event.target.value.replace(/\D/g, '');
    if (input.length > 10) {
      this.ngControl.control?.setValue(input.slice(0, 10));
    } else {
      this.ngControl.control?.setValue(input);
    }
  }
}