interface API_RESPONSE {
    data: any[];
    errors?: string[];
    message: string;
    status: boolean;
  }
  interface SignUpPayload {
    full_name: string;
    mobile_number: string;
    email: string;
    password: string;
  }
  
  interface LoginPayload {
    email: string;
    password: string;
  }
  
  interface ProviderSearchPayload {
    age: number,
    gender: string,
    location: string
  }

  interface ProductParams {
    borrowerId : string,
    age: number,
    gender: string,
    location: string
  }

  interface RefreshTokenPayload {
    refresh: string;
  }

  interface SendVerifyOtpPayload {
    mobile_number: string;
    otp?: string;
  }

  interface UPLOAD_DOCUMENT_DATA {
    modalTitle: string;
    fieldName: string;
    submitButtonLabel: string;
    cancelButtonLabel: string;
    dataRequired: {
      panCardNumber: boolean;
      aadharCardNumber: boolean;
      pan_file: boolean;
    }
  }

  interface S3_DOCUMENT_DATA {
    pan_card_image : File;
  }
  interface PAN_CARD_UPLOAD {
    pan_image_url: string;
    user_id: string;
    user_type: 'borrower' | 'agent';
    pan_card_number: string;
    aadhar_card_number: string;
  }

  interface ProviderInitPayload {
    transaction_id: string;
    bpp_id: string;
    bpp_uri: string;
    provider_id: string;
    selected_product: any;
    participant_id: string;
    agent_kyc_reference_number?: string;
    borrower_kyc_reference_number?: string;
  }

  interface ProviderConfirmPayload {
    transaction_id: string;
    bpp_id: string;
    bpp_uri: string;
    provider_id: string;
    participant_id: string;
    selected_product: any;
    agent_kyc_reference_number: string;
    borrower_kyc_reference_number: string;
  }

  interface Borrower {
    id: string;
    first_name: string;
    last_name: string;
    dob: string;
    gender: string;
    mobile_number: string;
    email: string;
    address_line_1: string;
    address_line_2: string;
    state: string;
    district: string;
    city: string;
    village: string;
    pincode: string;
    aadhar_card_number: string;
    pan_card_number: string;
    bank_account_holder_name: string;
    bank_name: string;
    bank_account_number: string;
    bank_ifsc_code: string;
    bank_branch: string;
    bank_account_type: string;
    created_at: string;
    updated_at: string;
    agent: string;
    pan_image_link: string;
  }
  interface PaymentDetails {
    order_status: string;
    grant_amount: string;
    order_id: string;
    order_date: string;
    order_time: string;
    transaction_id: string;
  }

  enum LoadingType {
    BUBBLES = 'bubbles',
    CIRCLES = 'circles',
    CIRCULAR = 'circular',
    CRESCENT = 'crescent',
    DOTS = 'dots',
    LINES = 'lines',
    LINES_SHARP = 'lines-sharp',
    LINES_SHARP_SMALL = 'lines-sharp-small',
    LINES_SMALL = 'lines-small'
}

enum SESSION_STORAGE_KEYS {
  ACTIVE_CONTEXT = 'active_context',
  SESSION_TOKEN = 'sessionToken',
  USER_INFO = 'userInfo'
}

interface LoadingMessage {
  endpoint: string;
  method: string;
  message: string;
}

interface BackButtonEvent {
  register: (id: number, callback: () => void) => void;
}
  
export { API_RESPONSE , SignUpPayload , LoginPayload , ProviderSearchPayload , Borrower , PaymentDetails , ProviderInitPayload , RefreshTokenPayload , LoadingType , LoadingMessage, ProviderConfirmPayload, SendVerifyOtpPayload , BackButtonEvent , SESSION_STORAGE_KEYS, ProductParams, UPLOAD_DOCUMENT_DATA, S3_DOCUMENT_DATA, PAN_CARD_UPLOAD}