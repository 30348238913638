import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';
import { ApiService } from 'src/app/shared/services/api.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(
    private router: Router,
    private activateRouter: ActivatedRoute,
    private sharedService: SharedService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.validateToken();
  }

  private validateToken(): void {
    const { token } = this.activateRouter.snapshot.params;
    const { refresh = '', borrowerId, fromApp } = this.activateRouter.snapshot.queryParams;

    if (!token) {
      this.redirectToNotFound();
      return;
    }

    const tokens = { access: token, refresh };
    this.storeAndValidateTokens(tokens, { borrowerId, fromApp });
  }

  private storeAndValidateTokens(tokens: { access: string, refresh: string }, context: { borrowerId?: string, fromApp?: string }): void {
    this.sharedService.setDataToSessionStorage({ data: tokens, key: SESSION_STORAGE_KEYS.SESSION_TOKEN });
    this.apiService.verifyToken().subscribe({
      next: (res: any) => {
        if (!res?.data) {
          this.resetUserSession();
        } else {
          this.handleSuccessfulValidation(res.data, context);
        }
      },
      error: () => {
        this.resetUserSession();
      }
    });
  }

  private handleSuccessfulValidation(userData: any, { borrowerId, fromApp }: { borrowerId?: string, fromApp?: string }): void {
    this.storeUserData(userData);
    this.navigateUser(borrowerId, fromApp);
  }

  private storeUserData(data: any): void {
    this.sharedService.setDataToSessionStorage({ data: data, key: SESSION_STORAGE_KEYS.USER_INFO });
    this.sharedService.userSessionCreated.next(true);
  }

  private navigateUser(borrowerId?: string, fromApp?: string): void {
    borrowerId ? this.router.navigate([`/borrowers/${borrowerId}`]) : this.router.navigate(['/'])
  }

  private resetUserSession(): void {
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN);
    this.redirectToNotFound();
  }

  private redirectToNotFound(): void {
    this.router.navigate(['/not-found']);
  }

}
