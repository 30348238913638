<ion-content>
    <ion-header>
      <ion-toolbar>
        <ion-title [innerHTML]="uploadDocumentData.modalTitle"></ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="onCancel()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-card class="upload-card" [formGroup]="form">
      <ion-item *ngIf="uploadDocumentData.dataRequired.panCardNumber">
        <ion-label position="floating">PAN Card Number</ion-label>
        <ion-input formControlName="panCardNumber" maxlength="10" inputmode="text" class="pan-card-input"></ion-input>
      </ion-item>
        <p class="error-message" *ngIf="form.controls['panCardNumber'].dirty && form.controls['panCardNumber'].hasError('required')">
          PAN Card Number is required
        </p>
        <p class="error-message" *ngIf="form.controls['panCardNumber'].dirty && form.controls['panCardNumber'].hasError('pattern')">
          Invalid PAN Card Number
        </p>
      <ion-item *ngIf="uploadDocumentData.dataRequired.aadharCardNumber">
        <ion-label position="floating">Aadhar Card Number</ion-label>
        <ion-input formControlName="aadharCardNumber" maxlength="12" inputmode="numeric"></ion-input>
      </ion-item>
        <p class="error-message" *ngIf="form.controls['aadharCardNumber'].dirty && form.controls['aadharCardNumber'].hasError('required')">
          Aadhar Card Number is required
        </p>
        <p class="error-message" *ngIf="form.controls['aadharCardNumber'].dirty && form.controls['aadharCardNumber'].hasError('pattern')">
          Invalid Aadhar Card Number
        </p>
      <ng-container *ngIf="uploadDocumentData.dataRequired.pan_file">
        <p class="file-upload-text">Please upload the pan card image</p>
        <div class="file-upload-container">
          <!-- <p class="error-message" *ngIf="form.invalid && form.touched">Please enter valid PAN and Aadhar card numbers</p> -->
          <div class="file-upload-box" *ngIf="!form.value.file" (click)="fileInput.click()">
            <ion-icon name="cloud-upload-outline" class="upload-icon"></ion-icon>
            <p class="upload-text">Click to Choose File</p>
          </div>
          <div *ngIf="form.value.file" class="file-info">
            <p class="file-name">{{ form.value.file.name }}</p>
            <ion-button color="light" (click)="removeFile()">
              <ion-icon slot="start" name="trash-outline"></ion-icon>
              Remove File
            </ion-button>
          </div>
          <input #fileInput type="file" (change)="onFileSelected($event)" hidden>
        </div>
      </ng-container>
    </ion-card>
  
    <div class="button-group">
        <ion-button color="light" expand="block" (click)="onCancel()">{{ uploadDocumentData.cancelButtonLabel }}</ion-button>
      <ion-button color="primary" expand="block" (click)="onSubmit()">{{ uploadDocumentData.submitButtonLabel }}</ion-button>
    </div>
  </ion-content>