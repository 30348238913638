import { environment } from "src/environments/environment";
import { Borrower } from "../types/types";

const BORROWER_DEFAULT_VALUES : Borrower = {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
    dob: '',
    gender: '',
    city: '',
    village: '',
    address_line_1: '',
    address_line_2: '',
    state: '',
    district: '',
    pincode: '',
    aadhar_card_number: '',
    pan_card_number: '',
    bank_account_holder_name: '',
    bank_name: '',
    bank_account_number: '',
    bank_ifsc_code: '',
    bank_branch: '',
    bank_account_type: '',
    created_at: '',
    updated_at: '',
    agent: '',
    pan_image_link: '',
};
const HIDE_HEADER_ROUTES = ['/login', '/signup']
export { BORROWER_DEFAULT_VALUES , HIDE_HEADER_ROUTES }
