<div *ngIf="userData?.full_name" class="main-header">
  <ion-menu contentId="main-content" side="start" menuId="main-menu">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Menu
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list>
        <ion-item button (click)="moveTOBorrowerList()">
          <ion-icon slot="start" name="people-outline"></ion-icon>
          <ion-label>Borrowers</ion-label>
        </ion-item>
        <ion-item button (click)="signOut()">
          <ion-icon slot="start" name="exit-outline"></ion-icon>
          <ion-label>Logout</ion-label>
        </ion-item>

      </ion-list>
    </ion-content>
  </ion-menu>
</div>

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <div class="logo-title" [routerLink]="['/borrowers']">
      <img src="assets/images/rg_logo.svg" alt="RG Logo" class="logo" />
      <strong>RG</strong>
    </div>
    <ion-buttons [collapse]="true" slot="end" *ngIf="userData?.full_name">
      <ion-button class="user-info-button">
        <ion-label class="username">{{ userData?.full_name || '--' }}</ion-label>
        <ion-avatar>
          <img class="avatar" alt="User Avatar" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
        </ion-avatar>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>